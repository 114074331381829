import { Action } from '@ngrx/store';

export enum ActionTypes {
  ChangeGridColumnPerRow = '[UiState] ChangeGridColumnPerRow',
  ToggleShowUsername = '[UiState] ShowUsername',
  Clear = 'Clear'
}

export class ClearAction implements Action {
  readonly type = ActionTypes.Clear;
}

export class ChangeGridColumnPerRowAction implements Action {
  readonly type = ActionTypes.ChangeGridColumnPerRow;

  constructor(public payload: number) {}
}

export class ToggleShowUsernameAction implements Action {
  readonly type = ActionTypes.ToggleShowUsername;
}

export type UiActions = ClearAction | ChangeGridColumnPerRowAction | ToggleShowUsernameAction;
