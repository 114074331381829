import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { TextareaDialogComponent } from './components/textarea-dialog/textarea-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { MaterialModule } from '../material/material.module';
import { PasswordDialogComponent } from './components/password-dialog/password-dialog.component';
import { DateDialogComponent } from './components/date-dialog/date-dialog.component';

@NgModule({
  declarations: [DialogComponent, TextareaDialogComponent, InputDialogComponent, PasswordDialogComponent, DateDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [DialogComponent, TextareaDialogComponent]
})
export class DialogModule {}
