import { createFeature, createReducer, on } from '@ngrx/store';
import { appActions } from '../actions/app.actions';
import { ActiveRelations } from '../contracts/active-relations';

export interface AppState {
  connected: boolean;
  user: any | null;
  relations: any | null;
  activeRelation: ActiveRelations;
  email: string;
  previousPage: string;
  apiUrl: string;
  cmpId: string;
}

export const initialState: AppState = {
  connected: true,
  activeRelation: null,
  previousPage: null,
  email: 'test@ah-automation.nl',
  apiUrl: null,
  cmpId: null,
  user: undefined,
  relations: undefined
};

export const appFeature = createFeature({
  name: 'appState',
  reducer: createReducer(
    initialState,
    on(appActions.clear, () => initialState),
    on(appActions.connectivitychange, (state, { conn }) => {
      return {
        ...state,
        connected: conn
      };
    }),
    on(appActions.adduser, (state, { user }) => {
      return {
        ...state,
        user: user
      };
    }),
    on(appActions.addrelations, (state, { rels }) => {
      return {
        ...state,
        relations: rels
      };
    }),
    on(appActions.changeactiverelation, (state, { activeRels }) => {
      return {
        ...state,
        activeRelation: activeRels
      };
    }),
    on(appActions.changeemail, (state, { email }) => {
      return {
        ...state,
        email: email
      };
    }),
    on(appActions.changeapiurl, (state, { apiurl }) => {
      return {
        ...state,
        apiUrl: apiurl
      };
    }),
    on(appActions.changecmpid, (state, { cmpId }) => {
      return {
        ...state,
        cmpId: cmpId
      };
    }),
    on(appActions.changepreviouspage, (state, { prevPage }) => {
      return {
        ...state,
        previousPage: prevPage
      };
    })
  )
});
