import { Action } from '@ngrx/store';

export enum ActionTypes {
  Clear = 'Clear',
  SetPickingDate = '[OrdqcState] SetPickingDate'
}
export class ClearAction implements Action {
  readonly type = ActionTypes.Clear;
}
export class SetPickingDate implements Action {
  readonly type = ActionTypes.SetPickingDate;
  constructor(public payload: Date) {}
}

export type ordqcActions = ClearAction | SetPickingDate;
