import * as UiActions from './../actions/ui.actions';

export interface UiState {
  gridColumnsPerRow: number;
  showUsername: boolean;
}

export const initialState: UiState = {
  gridColumnsPerRow: 4,
  showUsername: false
};

export function uiReducer(state = initialState, action: UiActions.UiActions): UiState {
  switch (action.type) {
    case UiActions.ActionTypes.Clear: {
      return initialState;
    }
    case UiActions.ActionTypes.ChangeGridColumnPerRow: {
      return {
        ...state,
        gridColumnsPerRow: action.payload
      };
    }
    case UiActions.ActionTypes.ToggleShowUsername: {
      return {
        ...state,
        showUsername: !state.showUsername
      };
    }
    default: {
      return state;
    }
  }
}
