import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavBarBtn } from '../../contracts/btn.contract';

@Component({
  selector: 'app-navbar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements AfterViewInit {
  @Input() nbTitle = 'Home';
  @Input() nbSubtitle;

  @Input() nbBtnLeft: NavBarBtn;
  @Input() nbBtnRight: NavBarBtn;

  @Input() nbBtnLeftEnabled = true;
  @Input() nbBtnRightEnabled = true;

  @Input() nbClassName = 'default';

  @Output() nbBtnLeftClick: EventEmitter<any> = new EventEmitter();
  @Output() nbBtnRightClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('content') content: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    if (this.content.nativeElement.childElementCount > 0) {
      // set offset to properly center title
      (this.content.nativeElement.children[0] as HTMLElement).style.marginLeft = `-${
        // future: handle more than 1 child element
        this.content.nativeElement.children[0].clientWidth - 24
      }px`;
    }
  }

  handleLeftButtonClick(): void {
    this.nbBtnLeftClick.emit();
  }

  handleRightButtonClick(): void {
    this.nbBtnRightClick.emit();
  }
}
