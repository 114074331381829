import { Action } from '@ngrx/store';
import { SysApiLog } from '../modules/shared/data/models/sysApiLog.dto';

export enum ActionTypes {
  Clear = 'Clear',
  ClearLogsAction = '[LogState] ClearLog',
  AddLogAction = '[LogState] AddLog'
}

export class ClearAction implements Action {
  readonly type = ActionTypes.Clear;
}

export class ClearLogsAction implements Action {
  readonly type = ActionTypes.ClearLogsAction;
}

export class AddLogAction implements Action {
  readonly type = ActionTypes.AddLogAction;

  constructor(public payload: SysApiLog) {}
}

export type LogActions = ClearAction | ClearLogsAction | AddLogAction;
