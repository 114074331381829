import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, interval } from 'rxjs';

@Injectable()
export class UpdateService {
  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate().then(() => console.log('checking for updates')));
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY')).subscribe(event => {
      console.log('Update found : ', event);
      this.promptUser();
    });
  }

  private promptUser(): void {
    console.log('Updating to new version');
    this.updates.activateUpdate().then(() => {
      document.location.reload();
    });
  }
}
