import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WhsOrderDataDTO } from '../../shared/data/models/whsDTOs/whsOrderData.dto';

export const scanActions = createActionGroup({
  source: 'Scan',
  events: {
    clear: emptyProps(),
    setScanOrder: props<{ whsOrderData: WhsOrderDataDTO }>()
  }
});
