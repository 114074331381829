import { NavBarBtn } from '../../contracts/btn.contract';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent {
  @Input() btn: NavBarBtn;

  @Output() click: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onClick(event: any): void {
    event.stopPropagation();
    this.click.emit(event);
  }
}
