import { UiState } from './../../reducers/ui.reducer';
import { NavBarBtn } from '../../modules/shared/navbar/contracts/btn.contract';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelectionListChange } from '@angular/material/list';
import { Store } from '@ngrx/store';
import { ClearAction, ToggleShowUsernameAction } from './../../actions/ui.actions';
import { AppState } from 'src/app/reducers/app.reducer';
import { AppService } from 'src/app/services/app/app.service';
import { DialogService } from 'src/app/modules/shared/dialog/services/dialog.service';
import { OrderState } from 'src/app/modules/veldhovenTablet/reducers/order.reducer';
import { AuthState } from 'src/app/modules/shared/auth/reducers/auth.reducer';
import { LogState } from 'src/app/reducers/log.reducer';
import { VehicleState } from 'src/app/modules/veldhovenTablet/reducers/vehicle.reducer';
import { appActions } from 'src/app/actions/app.actions';

@Component({
  selector: 'app-config',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  navbarButtonLeft: NavBarBtn;
  navbarTitle: string;
  options: { label: string; prop: string; dispatchEvent: any; selected?: boolean }[];

  public newEmail: string;
  public oldEmail: string;
  public newApiUrl: string;
  public oldApiUrl: string;
  public newCmpId: string;
  public oldCmpId: string;

  constructor(
    private router: Router,
    private uiStore: Store<UiState>,
    private logStore: Store<LogState>,
    private appStore: Store<AppState>,
    private orderStore: Store<OrderState>,
    private vehicleStore: Store<VehicleState>,
    private authStore: Store<AuthState>,
    private appService: AppService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.navbarTitle = 'Settings';
    this.navbarButtonLeft = {
      icon: 'arrow_back_ios'
    };
    this.options = [{ label: 'Show username', prop: 'showUsername', dispatchEvent: new ToggleShowUsernameAction() }];
    this.uiStore
      .select((state: any) => state.uiState)
      .subscribe(uiState => {
        this.options.map(option => {
          option.selected = uiState[option.prop];
          return option;
        });
      });
    this.appStore
      .select((state: any) => state.appState)
      .subscribe(appState => {
        this.oldEmail = appState.email;
        this.newEmail = this.newEmail ?? this.oldEmail;
        this.oldApiUrl = appState.apiUrl;
        this.newApiUrl = this.newApiUrl ?? this.oldApiUrl;
        this.oldCmpId = appState.cmpId;
        this.newCmpId = this.newCmpId ?? this.oldCmpId;
      });
  }

  onSelectionChange(change: MatSelectionListChange): void {
    console.log(change);
    this.uiStore.dispatch(change.options.find(op => op.value.prop === 'showUsername').value.dispatchEvent);
  }

  public confirmNewEmail(): void {
    this.appService.dispatch(appActions.changeemail({ email: this.newEmail }));
  }

  public cancelNewEmail(): void {
    this.newEmail = this.oldEmail;
  }

  public confirmNewApiUrl(): void {
    this.appService.dispatch(appActions.changeapiurl({ apiurl: this.newApiUrl }));
  }

  public cancelNewApiUrl(): void {
    this.newApiUrl = this.oldApiUrl;
  }

  public confirmNewCmpId(): void {
    this.appService.dispatch(appActions.changecmpid({ cmpId: this.newCmpId }));
  }

  public cancelNewCmpId(): void {
    this.newCmpId = this.oldCmpId;
  }

  public clearLocalStorage(): void {
    this.dialog
      .openDialog(
        {
          title: 'Clear localstorage?',
          message: 'Weet u zeker dat u de localstorage wilt clearen? Actieve of afgeronde orders in de localstorage worden verwijderd.'
        },
        { width: '600px' }
      )
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.uiStore.dispatch(new ClearAction());
          this.logStore.dispatch(new ClearAction());
          this.orderStore.dispatch(new ClearAction());
          this.authStore.dispatch(new ClearAction());
          this.vehicleStore.dispatch(new ClearAction());
          this.appService.dispatch(appActions.clear());
        }
      });
  }

  public navbarButtonLeftClick(): void {
    this.router.navigate(['login']);
  }
}
