import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
  accessToken: string;
  refreshToken: string;
  expiresAt: string;
  createdAt: number;
}

export const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
  createdAt: null
};

export function authReducer(state = initialState, action: AuthActions.AuthActions): AuthState {
  switch (action.type) {
    case AuthActions.ActionTypes.Clear: {
      return initialState;
    }
    case AuthActions.ActionTypes.Login: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        createdAt: action.payload.createdAt
      };
    }
    case AuthActions.ActionTypes.Logout: {
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        expiresAt: null,
        createdAt: null
      };
    }
    default: {
      return state;
    }
  }
}
