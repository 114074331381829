import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

/**
 * Used for opening and listening to a material snackbar
 */
@Injectable()
export class Snackbar {
  /**
   * Subject that emits when the action button is pressed
   * @returns the text of the action button
   */
  public onAction: Subject<string> = new Subject<string>();
  public isOpen: boolean;

  constructor(
    // tslint:disable-next-line: variable-name
    private _snackbar: MatSnackBar
  ) {}

  /**
   * Opens the snackbar
   * @param message The message to display
   * @param close The text on the action/close button
   * @param error Determines if it's announcing an error
   * @param time time in seconds, -1 for persists
   */
  public open(message: string, close: string, error?: boolean, time = 5): void {
    if (message != null) {
      this.isOpen = true;
      const snackbarRef = this._snackbar.open(message, close, {
        duration: time < 0 ? 0 : time * 1000,
        panelClass: error ? 'error-snackbar' : null
      });
      snackbarRef.afterDismissed().subscribe(() => (this.isOpen = false));
      snackbarRef.onAction().subscribe(() => this.onAction.next(close));
    }
  }

  /**
   * displays a message and disappears
   * @param message The message to display
   * @param time time in seconds
   */
  public message(
    message: string,
    time = 5,
    position?: { vertical?: MatSnackBarVerticalPosition; horizontal?: MatSnackBarHorizontalPosition }
  ): void {
    this.isOpen = true;
    this._snackbar
      .open(message, null, {
        duration: time * 1000,
        verticalPosition: position?.vertical ?? 'bottom',
        horizontalPosition: position?.horizontal ?? 'center'
      })
      .afterDismissed()
      .subscribe(() => (this.isOpen = false));
  }

  /**
   * Close the snackbar
   */
  public close(): void {
    this._snackbar.dismiss();
  }
}
