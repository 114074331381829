import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Snackbar } from './classes/snackbar';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [Snackbar]
})
export class SnackbarModule {}
