import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import PwValidators from './password.validators';

@Component({
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
  animations: [
    trigger('showHideError', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-25%)' }),
        animate('0.3s', style({ opacity: 1, transform: 'translateY(0%)' }))
      ])
    ])
  ]
})
export class PasswordDialogComponent {
  public newPassForm: UntypedFormGroup = this._fb.group(
    {
      password: this._fb.control(null, [
        Validators.required,
        Validators.minLength(8),
        PwValidators.hasUpperCase,
        PwValidators.hasLowerCase,
        PwValidators.hasSpecial,
        PwValidators.hasDigit
      ]),
      passwordCheck: this._fb.control(null, Validators.required)
    },
    { validators: PwValidators.matchesValue() }
  );

  constructor(private _dialogRef: MatDialogRef<PasswordDialogComponent, string>, private _fb: UntypedFormBuilder) {
    this._dialogRef.disableClose = true;
  }

  onSubmit(): any {
    if (this.newPassForm.valid) return this.newPassForm.get('password').value;
  }
}
