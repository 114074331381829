import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAlert, DialogButton } from '../../contracts/dialog-types';
import IDialogData from '../../contracts/idialog-data';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss', '../../styles/dialog.scss']
})
export class DialogComponent {
  actions: DialogButton[];

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    this.actions = data.actions;
  }

  /**
   * getAlertColor
   */
  public getAlertClass(alert: DialogAlert): string {
    switch (alert) {
      case DialogAlert.ALERT:
        return 'titlebar-alert';
      case DialogAlert.WARN:
        return 'titlebar-warn';
      default:
        return;
    }
  }

  /**
   * isArray
   */
  public isArray(message: string | string[]): message is string[] {
    return Array.isArray(message);
  }
}
