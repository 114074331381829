import { AuthGuard } from './modules/shared/auth/guards/auth.guard';
import { GuestOnlyGuard } from './modules/shared/auth/guards/guest-only.guard';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomeComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent, canActivate: [GuestOnlyGuard] },
      { path: 'settings', component: SettingsComponent },
      { path: 'whs', loadChildren: () => import('././modules/whs/pasWhs.module').then(m => m.PasWhsModule), canActivate: [AuthGuard] },
      {
        path: 'dlv',
        loadChildren: () => import('././modules/veldhovenTablet/pasDlv.module').then(m => m.PasDlvModule),
        canActivate: [AuthGuard]
      },
      { path: 'ordqc', loadChildren: () => import('././modules/ordQc/ordqc.module').then(m => m.OrdqcModule), canActivate: [AuthGuard] },
      {
        path: 'scan',
        loadChildren: () => import('././modules/pasScan/pasScan.module').then(m => m.ScanModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
