import { Component, Input } from '@angular/core';
import { SidebarItem } from '../../contracts/sidebar-item.contract';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() items: SidebarItem[];
  @Input() closeRef: MatSidenav;

  constructor(private router: Router) {}

  onClose() {
    this.closeRef.close();
  }

  onSidebarItemClick(sidebarItem: SidebarItem) {
    this.router.navigate([sidebarItem.link]);
  }
}
