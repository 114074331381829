import { Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private online$: Observable<boolean>;

  constructor() {
    //
  }

  connectivityCheck() {
    this.online$ = merge(of(navigator.onLine));
  }

  getConnectionState(): Observable<boolean> {
    return this.online$;
  }
}
