import { faBox, faCarSide, faQrcode, faQuestion, faTruck } from '@fortawesome/free-solid-svg-icons';
import { UiState } from '../../reducers/ui.reducer';
import { AppState } from '../../reducers/app.reducer';
import { Store } from '@ngrx/store';
import { SidebarItem } from '../../contracts/sidebar-item.contract';
import { Tile } from '../../modules/shared/grid/contracts/tile.contract';
import { NavBarBtn } from '../../modules/shared/navbar/contracts/btn.contract';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SyncService } from 'src/app/services/sync/sync.service';
import { AppService } from 'src/app/services/app/app.service';
import { concatMap, delay, filter, first, retry, switchMap } from 'rxjs/operators';
import { iif, of, Subscription, throwError } from 'rxjs';
import { RightsHttpService } from 'src/app/modules/shared/data/services/http/resources/rights.http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public navbarButtonLeft: NavBarBtn;
  public navbarButtonRight: NavBarBtn;
  public navbarTitle: string;
  public sidebarOpened: boolean;
  public sidebarItems: SidebarItem[];
  public tiles: Tile[];
  public loading: boolean;
  public uiState: UiState;
  public appState: AppState;
  $rightsSubscription: Subscription;

  constructor(
    private _syncService: SyncService,
    private _store: Store<AppState>,
    private _router: Router,
    private _rightsService: RightsHttpService,
    private _appService: AppService
  ) {}

  public ngOnInit(): void {
    this._syncService.connectivityCheck();
    this.navbarTitle = 'Dashboard';
    this.navbarButtonLeft = {
      icon: 'arrow_back_ios',
      label: 'HOME'
    };
    this.navbarButtonRight = {
      icon: 'settings'
    };
    this.sidebarItems = [];
    this.tiles = [];
    this._store
      .select((state: any) => state)
      .subscribe(state => {
        this.uiState = state.uiState;
        this.appState = state.appState;
      });
    this.getRightsForUser();
  }

  public ngOnDestroy(): void {
    this.$rightsSubscription.unsubscribe();
  }

  public navbarButtonLeftClick(): void {
    this._router.navigate(['']);
  }

  public navbarButtonRightClick(): void {
    this._router.navigate(['settings']);
  }

  public getRightsForUser(): void {
    this.loading = true;
    this.$rightsSubscription = this._appService
      .getUser()
      .pipe(
        first(),
        switchMap(user =>
          this._rightsService.getRightsForUser(user.gd).pipe(
            retry({
              delay: errors =>
                // Retry once connectivity state becomes true
                // due to the waiting nature of this we need to unsubscribe in ngOnDestroy in case the user navigates away during fetch
                errors.pipe(
                  switchMap(() => this._appService.getConnectivity().pipe(filter(con => con === true))),
                  // if connected, yet still fails, something is wrong with the api,
                  concatMap((e: boolean, i: number) => {
                    return iif(
                      // retry max of 3 times
                      () => i === 3,
                      // throw error
                      throwError(() => new Error('failed to connect to API')),
                      // wait 1 second before retrying
                      of(e).pipe(delay(1000))
                    );
                  })
                )
            })
          )
        )
      )
      .subscribe({
        next: x => {
          // #region  WHS
          const loc = x.find(x => x == 'WEBPRDOUTLOC');
          if (loc != undefined) {
            this.sidebarItems.push({ label: 'Dot naar locatie', link: 'whs/selectterminal/prd-out-to-loc' });
            this.tiles.push({
              text: 'Productie naar locatie',
              className: 'fin',
              icon: 'barcode-scan',
              link: 'whs/selectterminal/prd-out-to-loc'
            });
          }
          const whsIn = x.find(x => x == 'WEBORDPICKING');
          if (whsIn != undefined) {
            this.sidebarItems.push({ label: 'Web order picking', link: 'whs/selectterminal/web-ord-picking' });
            this.tiles.push({ text: 'Web order picking', className: 'fin', icon: faBox, link: 'whs/selectterminal/web-ord-picking' });
          }
          //#endregion

          //#region ordqc
          if (this.appState.cmpId.includes('622622') || this.appState.cmpId.includes('611611')) {
            // Do not show this for SOUPY and HEMERT
          } else {
            this.sidebarItems.push({ label: 'Quality control', link: 'ordqc/ordQcInfo' });
            this.tiles.push({ text: 'Quality control', className: 'qc', icon: faQuestion, link: 'ordqc/ordQcInfo' });
          }
          //#endregion

          //#region ORD
          const dlv = x.find(x => x == 'WEBDLV');
          if (dlv != undefined) {
            this.sidebarItems.push({ label: 'Aflever app', link: 'dlv/selectlicenseplate' });
            this.tiles.push({ text: 'Aflever app', className: 'ord', icon: faTruck, link: 'dlv/selectlicenseplate' });
          }
          const stat = x.find(x => x == 'WEBSTAT');
          if (stat != undefined) {
            this.sidebarItems.push({ label: 'Status auto', link: 'dlv/selectcar' });
            this.tiles.push({ text: "Status auto's", className: 'ord', icon: faCarSide, link: 'dlv/selectcar' });
          }
          //#endregion

          //#region SCAN
          const scan = x.find(x => x == 'WEBSCAN');
          if (scan != undefined) {
            this.sidebarItems.push({ label: 'Scan', link: 'scan/dashboard' });
            this.tiles.push({ text: 'Scan', className: 'ord', icon: faQrcode, link: 'scan/dashboard' });
          }
          //#endregion

          this.loading = false;
        },
        error: e => console.log('Error: ', e.message)
      });
  }
}
