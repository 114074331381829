import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITextareaDialogData, TextareaDialogData } from '../../contracts/itextarea-dialog-data';

@Component({
  selector: 'app-textarea-dialog',
  templateUrl: './textarea-dialog.component.html',
  styleUrls: ['./textarea-dialog.component.scss']
})
export class TextareaDialogComponent {
  textareaInput: string = this.data?.content;
  public textfieldDialogData = TextareaDialogData;

  constructor(public dialogRef: MatDialogRef<TextareaDialogComponent, string>, @Inject(MAT_DIALOG_DATA) public data: ITextareaDialogData) {}

  public submitEntry(): string {
    return this.textareaInput;
  }
}
