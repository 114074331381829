import { Action } from '@ngrx/store';
import { TerminalDTO } from '../../shared/data/models/whsDTOs/terminal.dto';
import { WhsOrderDataDTO } from '../../shared/data/models/whsDTOs/whsOrderData.dto';

export enum ActionTypes {
  Clear = 'Clear',
  SetTerminal = '[MstState] SetTerminal',
  SetPickingOrder = '[MstState] SetPickingOrder',
  SetPickingDate = '[MstState] SetPickingDate'
}
export class ClearAction implements Action {
  readonly type = ActionTypes.Clear;
}
export class SetTerminal implements Action {
  readonly type = ActionTypes.SetTerminal;
  constructor(public payload: TerminalDTO) {}
}
export class SetPickingOrder implements Action {
  readonly type = ActionTypes.SetPickingOrder;
  constructor(public payload: WhsOrderDataDTO) {}
}
export class SetPickingDate implements Action {
  readonly type = ActionTypes.SetPickingDate;
  constructor(public payload: Date) {}
}

export type WhsActions = ClearAction | SetTerminal | SetPickingOrder | SetPickingDate;
