import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/modules/shared/auth/services/auth/auth.service';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiState } from './reducers/ui.reducer';
import { ChangeGridColumnPerRowAction } from './actions/ui.actions';
import { SyncService } from './services/sync/sync.service';
import { AppState } from './reducers/app.reducer';
import { DynamicConfiguration } from './dynamic-configuration';
import { AppService } from './services/app/app.service';
import { first } from 'rxjs/operators';
import { UpdateService } from './services/service-worker/update.service';
import { appActions } from './actions/app.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public authStateSubscription: Subscription;
  private $_connectionSubscription: Subscription;

  public isConnected = true;
  public internetConnection: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    public syncService: SyncService,
    public dynamicConfig: DynamicConfiguration,
    private uiStore: Store<UiState>,
    private appStore: Store<AppState>,
    private appService: AppService,
    private swUpdate: UpdateService,
    private translate: TranslateService,
  ) {
    // Check auth state when opening app
    this.authStateSubscription = authService.getAuthState().subscribe(authState => {
      if (!authState.accessToken) {
        router.navigate(['login']);
      }
    });

    // check the url to see which db we should connect to
    this.appService
      .getActiveRelation()
      .pipe(first())
      .subscribe(rel => {
        const activeRel = dynamicConfig.getActiveRelation(window.location.hostname);
        // if the active rel is different from the current one, that means it must be set
        if (activeRel !== rel) {
          this.appStore.dispatch(appActions.changeactiverelation({ activeRels: activeRel }));
          this.appService.dispatch(appActions.changeapiurl({ apiurl: dynamicConfig.getApiUrl(activeRel, rel) }));
          this.appService.dispatch(appActions.changecmpid({ cmpId: dynamicConfig.getCmpId() }));
        }
      });
    this.getScreenSize();
    translate.setDefaultLang('nl'); translate.use('nl');
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    if (window.innerWidth < 768) {
      this.uiStore.dispatch(new ChangeGridColumnPerRowAction(1));
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      this.uiStore.dispatch(new ChangeGridColumnPerRowAction(2));
    } else {
      this.uiStore.dispatch(new ChangeGridColumnPerRowAction(4));
    }
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
    this.$_connectionSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.swUpdate.checkForUpdates();
  }
}
