import * as Actions from '../actions/vehicle.actions';
import { StoredVehicleDTO } from '../../shared/data/models/vehicle.dto';

export interface VehicleState {
  licenseplate: string;
  selectedVehicles: StoredVehicleDTO[];
}

export const initialState: VehicleState = {
  licenseplate: null,
  selectedVehicles: null
};

export function vehicleReducer(state = initialState, action: Actions.VehicleActions): VehicleState {
  switch (action.type) {
    case Actions.ActionTypes.SetLicensePlate: {
      return {
        ...state,
        licenseplate: action.payload
      };
    }
    case Actions.ActionTypes.SetSelectedVehicles: {
      return {
        ...state,
        selectedVehicles: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
