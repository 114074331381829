import * as LogActions from '../actions/log.actions';
import { SysApiLog } from '../modules/shared/data/models/sysApiLog.dto';

export interface LogState {
  logs: SysApiLog[];
}

export const initialState: LogState = {
  logs: []
};

export function logReducer(state = initialState, action: LogActions.LogActions): LogState {
  switch (action.type) {
    case LogActions.ActionTypes.ClearLogsAction: {
      return {
        ...state,
        logs: []
      };
    }
    case LogActions.ActionTypes.AddLogAction: {
      state.logs.push(action.payload);
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}
