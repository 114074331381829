import { Injectable } from '@angular/core';
import { ActiveRelations } from './contracts/active-relations';
import { AppService } from './services/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicConfiguration {
  private apiUrl: string;
  private activeRelation: ActiveRelations;

  constructor(private appService: AppService) {
    this.appService.getApiUrl().subscribe(url => (this.apiUrl = url));
    this.appService.getActiveRelation().subscribe(rel => (this.activeRelation = rel));
  }

  public getApiUrl(
    activeRelation: ActiveRelations = this.activeRelation || this.getActiveRelation(window.location.hostname),
    oldRel?: ActiveRelations
  ): string {
    function getDefaultValue(rel: ActiveRelations): string {
      switch (rel) {
        case ActiveRelations.HEMERT:
          return 'https://webapi.poeliersbedrijfvanhemert.nl';
        case ActiveRelations.VELDHOVEN:
          return 'https://webapi.veldhovenonlinebestellen.nl';
        case ActiveRelations.SOUPY:
          return 'https://cloud-api.pasys.nl';
        case ActiveRelations.LOCAL:
          return 'https://webapi.veldhovenonlinebestellen.nl';
        //return 'http://localhost:5000';
        case ActiveRelations.LOCAL_IP:
        // for testing tablets on --host 0.0.0.0
        case ActiveRelations.INTERN:
          return 'https://ah-api-intern.pasys.nl';
        default:
          throw new Error('Onbekend domein');
      }
    }
    // if the api url is not set, we get the default one
    if (!oldRel && (!this.apiUrl || this.apiUrl === getDefaultValue(activeRelation))) {
      return getDefaultValue(activeRelation);
    }
    if (oldRel && (!this.apiUrl || this.apiUrl === getDefaultValue(oldRel))) {
      return getDefaultValue(activeRelation);
    }
    return this.apiUrl;
  }

  /**
   * Gets the company id based on the active relation given
   */
  public getCmpId(activeRelation: ActiveRelations = this.activeRelation): string {
    switch (activeRelation) {
      case ActiveRelations.HEMERT:
        return '611611';
      case ActiveRelations.VELDHOVEN:
        return '311038';
      case ActiveRelations.LOCAL:
      case ActiveRelations.INTERN:
      // for testing tablets on --host 0.0.0.0
      case ActiveRelations.LOCAL_IP:
        return '311038_remote'; //return '311038_local';
      case ActiveRelations.SOUPY:
        return '622622_remote';
      default:
        throw new Error('Onbekend domein');
    }
  }

  /**
   * Returns a boolean depending on whether the temperature input is required
   */
  public getOrderSigningTmpRequired(
    activeRelation: ActiveRelations = this.activeRelation || this.getActiveRelation(window.location.hostname)
  ): boolean {
    switch (activeRelation) {
      case ActiveRelations.HEMERT:
        return true;
      case ActiveRelations.LOCAL:
      case ActiveRelations.VELDHOVEN:
      case ActiveRelations.INTERN:
      // for testing tablets on --host 0.0.0.0
      case ActiveRelations.LOCAL_IP:
      default:
        return false;
    }
  }

  public getActiveRelation(url: string): ActiveRelations {
    const values = Object.values(ActiveRelations);
    return values[values.findIndex(page => url.includes(page.valueOf().toLowerCase()))];
  }
}
