import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicConfiguration } from 'src/app/dynamic-configuration';
import { LoginDTO, LoginResponse } from '../../../models/login.dto';

@Injectable({
  providedIn: 'root'
})
export class UserDataHttpService {
  constructor(protected http: HttpClient, protected configuration: DynamicConfiguration) {}

  public postLogin(model: LoginDTO): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.configuration.getApiUrl()}/PostFetchTokenV2`, model);
  }

  public postRefresh(refreshToken: string): Observable<any> {
    return <Observable<LoginResponse>>this.http.post(
      `${this.configuration.getApiUrl()}/PostRefreshToken`,
      {},
      {
        params: {
          token: refreshToken
        }
      }
    );
  }

  public getUser(userName: string): Promise<any> {
    return this.http
      .get(`${this.configuration.getApiUrl()}/api/Auth/GetUsrByName`, {
        params: {
          userName: userName
        }
      })
      .toPromise() as Promise<any>;
  }

  updatePassword(rel_gd: string, password: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.configuration.getApiUrl()}/api/Auth/UpdatePassword`, { rel_gd: rel_gd, password: password });
  }
}
