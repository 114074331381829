import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WhsService } from 'src/app/modules/whs/services/whs.service';
import { IDateDialogData } from '../../contracts/idate-input-dialog-data';

@Component({
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent {
  defaultDate: Date;

  constructor(
    private _whsService: WhsService,
    public dialogRef: MatDialogRef<DateDialogComponent, string | number>,
    @Inject(MAT_DIALOG_DATA) public data: IDateDialogData
  ) {
    this.defaultDate = new Date();
    // reset selected date if there is any in storage
    // also a default date for the user, today is used most of the times
    this._whsService.setPickingDate(this.defaultDate);
  }

  valueChanged(event: any) {
    console.log('Date changed: ', event.value);
    // save to localstorage
    this._whsService.setPickingDate(event.value);
  }
}
