import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TerminalDTO } from '../../shared/data/models/whsDTOs/terminal.dto';
import { WhsOrderDataDTO } from '../../shared/data/models/whsDTOs/whsOrderData.dto';
import { SetPickingDate, SetPickingOrder, SetTerminal } from '../actions/whs.actions';
import { WhsState } from '../reducers/whs.reducer';

@Injectable({
  providedIn: 'root'
})
export class WhsService {
  constructor(private whsStore: Store<WhsState>) {}

  public getWhsState(): Observable<WhsState> {
    return this.whsStore.select((state: any) => state.whsState);
  }

  public dispatch(action: Action): void {
    this.whsStore.dispatch(action);
  }

  //#region Terminal
  public setTerminal(terminal: TerminalDTO): void {
    this.whsStore.dispatch(new SetTerminal(terminal));
  }

  public getTerminal(): Observable<TerminalDTO> {
    return this.getWhsState().pipe(map(({ terminal = null }) => terminal));
  }
  //#endregion

  //#region Picking Order
  public setPickingOrder(order: WhsOrderDataDTO): void {
    this.whsStore.dispatch(new SetPickingOrder(order));
  }

  public getPickingOrder(): Observable<WhsOrderDataDTO> {
    return this.getWhsState().pipe(map(({ pickingOrder = null }) => pickingOrder));
  }
  //#endregion

  //#region Picking Date
  setPickingDate(date: Date): void {
    this.whsStore.dispatch(new SetPickingDate(date));
  }

  getPickingDate(): Observable<Date> {
    return this.getWhsState().pipe(map(({ pickingDate = null }) => pickingDate));
  }
  //#endregion
}
