import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginAction, LogoutAction } from '../../actions/auth.actions';
import { AuthState } from '../../reducers/auth.reducer';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private store: Store<AuthState>) {}

  static isAuthorized(): boolean {
    let authState = <AuthState>JSON.parse(localStorage.getItem('authState'));

    console.log(authState);
    console.log(new Date(authState.createdAt).getUTCDate());
    console.log(new Date(Date.now()).getUTCDate());

    // After midnight
    if (authState && authState['accessToken'] && new Date(authState.createdAt).getUTCDate() != new Date(Date.now()).getUTCDate()) {
      localStorage.removeItem('authState');
      authState = null;
    }

    return !!(authState && authState['accessToken']);
  }

  getRefreshToken(): Observable<string> {
    return this.getAuthState().pipe(map(x => x.refreshToken));
  }

  getAccessToken(): Observable<string> {
    return this.getAuthState().pipe(map(x => x.accessToken));
  }

  async getAccessTokenPayload(): Promise<any> {
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(await this.getAccessToken().toPromise());
  }

  login(accessToken: string, refreshToken: string, expiresAt: string): void {
    this.store.dispatch(
      new LoginAction({
        accessToken: accessToken.replace('Bearer ', ''),
        refreshToken: refreshToken,
        expiresAt: expiresAt,
        createdAt: Date.now()
      })
    );
  }

  getAuthState(): Observable<any> {
    return this.store.select((state: any) => state.authState);
  }

  logout(): void {
    this.store.dispatch(new LogoutAction());
  }
}
