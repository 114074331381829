import { LoginDTO, LoginResponse } from '../../modules/shared/data/models/login.dto';
import { AuthService } from '../../modules/shared/auth/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserDataHttpService } from '../../modules/shared/data/services/http/resources/userData.http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ActiveRelations } from 'src/app/contracts/active-relations';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PasswordDialogComponent } from 'src/app/modules/shared/dialog/components/password-dialog/password-dialog.component';
import { AppService } from 'src/app/services/app/app.service';
import { appActions } from 'src/app/actions/app.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public navbarTitle: string;
  public selectedCompany: ActiveRelations;
  public loggingIn: boolean;
  public navbarClassName: string;
  private settingsButtonPressed = 0;
  public version = environment.version;
  public cmpId: string;
  loginForm: FormGroup;

  deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;
  ipAddress;
  replacePassword: string;

  public get activeRelations(): typeof ActiveRelations {
    return ActiveRelations;
  }

  constructor(
    private _userService: UserDataHttpService,
    private _authService: AuthService,
    public route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _appService: AppService
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    console.log('Start init login');
    this.loginForm.controls['username'].setValue(null);
    this.loginForm.controls['password'].setValue(null);
    this.navbarTitle = 'DriversPortal';
    this.navbarClassName = 'ord';
    this._appService
      .getAppState()
      .pipe(first())
      .subscribe(appState => {
        this.selectedCompany = appState.activeRelation;
        this.cmpId = appState.cmpId;
      });
  }

  async login(): Promise<void> {
    this.loggingIn = true;
    if (!this.loginForm.valid) {
      this.loggingIn = false;
      return;
    }

    const loginParams: LoginDTO = {
      username: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value,
      cmp_id: this.cmpId
    };
    console.log('Loginparams: ', loginParams);
    this._userService
      .postLogin(loginParams)
      .pipe(first())
      .subscribe({
        next: async (loginResponse: LoginResponse) => {
          const replacement = await this.checkPasswordStrength(this.loginForm.controls['password'].value);
          this._authService.login(loginResponse.access_token, loginResponse.refresh_token, loginResponse.expires);
          if (replacement)
            this._userService.updatePassword(loginResponse.syshumres_gd, replacement).subscribe(r => {
              console.log('r', r);
              this._appService.dispatch(
                appActions.adduser({
                  user: {
                    gd: loginResponse.syshumres_gd,
                    syscompany_gd: loginResponse.syscompany_gd,
                    name: loginResponse.displayName,
                    loginname: this.loginForm.controls['username'].value
                  }
                })
              );
              const queryParams = this.route.snapshot.queryParams;
              if (queryParams.hasOwnProperty('returnUrl')) {
                const returnUrl = queryParams.returnUrl;
                this._router.navigate([returnUrl]).then();
              } else {
                this._router.navigate(['/']).then();
              }
            });
          else {
            this._appService.dispatch(
              appActions.adduser({
                user: {
                  gd: loginResponse.syshumres_gd,
                  syscompany_gd: loginResponse.syscompany_gd,
                  name: loginResponse.displayName,
                  loginname: this.loginForm.controls['username'].value
                }
              })
            );
            const queryParams = this.route.snapshot.queryParams;
            if (queryParams.hasOwnProperty('returnUrl')) {
              const returnUrl = queryParams.returnUrl;
              this._router.navigate([returnUrl]).then();
            } else {
              this._router.navigate(['/']).then();
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.loggingIn = false;
        }
      });
  }

  private checkPasswordStrength(passw: string): Promise<string | void> {
    return new Promise(resolve => {
      if (
        // length is min 8
        passw.length >= 8 &&
        // has a lowercase letter
        /[a-z]+/.test(passw) &&
        // has uppercase letter
        /[A-Z]+/.test(passw) &&
        // has digit
        /\d+/.test(passw) &&
        // has special character
        /\W/.test(passw)
      )
        // password is strong, resolve instantly
        resolve();
      // password is too weak, open dialog for new password
      else
        this._dialog
          .open(PasswordDialogComponent)
          .afterClosed()
          .subscribe((a: string) => {
            resolve(a);
          });
    });
  }

  /**
   * countOpenSettings
   */
  public countOpenSettings(): void {
    if (this.settingsButtonPressed === 4) {
      this._router.navigate(['settings']);
      return;
    }
    this.settingsButtonPressed++;
  }
}
