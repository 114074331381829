import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicConfiguration } from 'src/app/dynamic-configuration';
import { Observable, map } from 'rxjs';
import { MstDTO } from '../../../models/mst.dto';
import { MstFilterDTO } from '../../../models/mstFilter.dto';
import { AppState } from 'src/app/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { ModMstDTO } from '../../../models/mod-mst.dto';

@Injectable({
  providedIn: 'root'
})
export class RightsHttpService {
  #controllerUrl = `${this._configuration.getApiUrl()}/Api/Mst/`;
  constructor(protected _http: HttpClient, protected _configuration: DynamicConfiguration, private _appStore: Store<AppState>) {}

  getRightsForUser(userGd: string): Observable<string[]> {
    return this._http.get<string[]>(`${this._configuration.getApiUrl()}/api/Auth/GetIndsForUsr?gd=${userGd}`);
  }

  getMst(filter: MstFilterDTO): Observable<MstDTO[]> {
    return this._http.post<MstDTO[]>(`${this.#controllerUrl}GetMst`, filter);
  }

  getRightsWithDescription(relGd: string): Observable<MstDTO[]> {
    return this._http.get<MstDTO[]>(`${this._configuration.getApiUrl()}/Api/Auth/GetRightsWithDescription?relGd=` + relGd);
  }

  getUserGd(): Observable<string> {
    return this.getAppState().pipe(map(x => x.user.gd));
  }

  getAppState(): Observable<any> {
    return this._appStore.select((state: any) => state.appState);
  }

  getAllActiveModules(): Observable<ModMstDTO[]> {
    return this._http.get<ModMstDTO[]>(`${this.#controllerUrl}GetAllActiveModules`);
  }
}
