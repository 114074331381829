export interface IDateDialogData {
  title: NonNullable<string>;
  label?: string;
  content: Date;
}

export const DateDialogData: IDateDialogData = {
  title: 'Voer datum in',
  content: new Date()
};
