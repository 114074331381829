import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export default class PasswordValidators {
  static hasUpperCase(control: AbstractControl): ValidationErrors {
    return /[A-Z]+/.test(control.value) ? null : { noUpperCase: true };
  }
  static hasLowerCase(control: AbstractControl): ValidationErrors {
    return /[a-z]+/.test(control.value) ? null : { noLowerCase: true };
  }
  static hasDigit(control: AbstractControl): ValidationErrors {
    return /\d+/.test(control.value) ? null : { noDigit: true };
  }
  static hasSpecial(control: AbstractControl): ValidationErrors {
    return /\W+/.test(control.value) ? null : { noSpecial: true };
  }
  static matchesValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password');
      const passwordCheck = control.get('passwordCheck');
      return password && passwordCheck && password.value !== passwordCheck.value ? { checkNotMatched: true } : null;
    };
  }
}
