export interface IInputDialogData {
  title?: string;
  type?: 'text' | 'number';
  label?: string;
  inputOptions?: ITextInputOptions | INumberInputOptions;
}

interface IInputRequired {
  required: boolean;
}
export interface ITextInputOptions extends IInputRequired {
  maxLength?: number;
}

export interface INumberInputOptions extends IInputRequired {
  max?: number;
  min?: number;
  step?: number;
}

export const InputDialogData: IInputDialogData = {
  title: 'Voer waarde in',
  type: 'text',
  label: 'Invoer'
};
