import * as Actions from '../actions/ordqc.actions';

export interface OrdqcState {
  pickingDate: Date;
}
export const initialState: OrdqcState = {
  pickingDate: null
};

export function ordqcReducer(state = initialState, action: Actions.ordqcActions): OrdqcState {
  switch (action.type) {
    case Actions.ActionTypes.Clear: {
      return initialState;
    }
    case Actions.ActionTypes.SetPickingDate: {
      return {
        ...state,
        pickingDate: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
