import { environment } from '../../../environments/environment';
import { NavBarBtn } from '../../modules/shared/navbar/contracts/btn.contract';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/shared/auth/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  navbarButtonLeft: NavBarBtn;
  navbarButtonRight: NavBarBtn;
  navbarButtonRightEnabled: boolean;
  navbarTitle: string;
  startButtonRoute: string;
  startButtonText: string;
  authStateSubscription: Subscription;
  public version = environment.version;
  public loading: boolean;

  constructor(private authService: AuthService) {
    this.authStateSubscription = this.authService.getAuthState().subscribe(authState => {
      if (authState.accessToken) {
        this.startButtonRoute = 'dashboard';
        this.startButtonText = 'Start application';
        this.navbarButtonRightEnabled = true;
      } else {
        this.startButtonRoute = 'login';
        this.startButtonText = 'Login';
        this.navbarButtonRightEnabled = false;
      }
    });
    this.navbarButtonRight = {
      icon: 'power_settings_new'
    };
  }

  ngOnInit(): void {
    this.navbarTitle = 'Home';
    this.loading = true;
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
  }

  navbarButtonRightClick(): void {
    this.authService.logout();
  }
}
