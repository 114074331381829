import { Injectable } from '@angular/core';
import IDialogData, { DialogData } from '../contracts/idialog-data';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { TextareaDialogComponent } from '../components/textarea-dialog/textarea-dialog.component';
import { ITextareaDialogData, TextareaDialogData } from '../contracts/itextarea-dialog-data';
import { IInputDialogData, InputDialogData } from '../contracts/iinput-dialog-data';
import { InputDialogComponent } from '../components/input-dialog/input-dialog.component';
import { DialogAlert } from '../contracts/dialog-types';
import { DateDialogComponent } from '../components/date-dialog/date-dialog.component';
import { DateDialogData, IDateDialogData } from '../contracts/idate-input-dialog-data';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  static getAlertClass(alert: DialogAlert): 'dialog-backdrop-alert' | 'dialog-backdrop-warn' {
    switch (alert) {
      case DialogAlert.NORMAL:
        return;
      case DialogAlert.ALERT:
        return `dialog-backdrop-alert`;
      case DialogAlert.WARN:
        return `dialog-backdrop-warn`;
    }
  }

  public openDialog(data: IDialogData, config?: MatDialogConfig<IDialogData>): MatDialogRef<DialogComponent, string | boolean> {
    if (!data.actions) {
      data.actions = DialogData.actions;
    }
    if (!data.title) {
      throw new Error('DialogService: Title is needed');
    }
    if (!data.message) {
      throw new Error('DialogService: Message is needed');
    }
    if (config) {
      config = {
        ...config,
        data,
        backdropClass: DialogService.getAlertClass(data.alert)
      };
    } else {
      config = {
        width: '300px',
        data,
        backdropClass: DialogService.getAlertClass(data.alert)
      };
    }
    return this.dialog.open(DialogComponent, config);
  }

  /**
   * Opens a dialog with a textarea input
   * @param data configurations for the dialog
   * @returns A MatDialogRef that returns a string on closed
   */
  public openTextareaDialog(data: ITextareaDialogData = TextareaDialogData): MatDialogRef<TextareaDialogComponent, string> {
    return this.dialog.open(TextareaDialogComponent, { data: data ?? null, width: '60vw' });
  }

  /**
   * Opens an input field dialog for either a number or text
   * @example
   */
  public openInputDialog(
    data: IInputDialogData = InputDialogData,
    config?: MatDialogConfig<IInputDialogData>
  ): MatDialogRef<InputDialogComponent, number | string> {
    if (config) {
      config = {
        ...config,
        data: data ?? InputDialogData
      };
    } else {
      config = {
        width: '60vw',
        data: data ?? InputDialogData
      };
    }
    return this.dialog.open(InputDialogComponent, config);
  }

  /**
   * Opens a dialog with a datepicker
   * @returns a MatDialog Ref that returnes a date on close
   */
  openDateDialog(
    data: IDateDialogData = DateDialogData,
    config?: MatDialogConfig<IDateDialogData>
  ): MatDialogRef<DateDialogComponent, Date> {
    if (config) {
      config = {
        ...config,
        data: data ?? DateDialogData
      };
    } else {
      config = {
        width: '60vw',
        data: data ?? DateDialogData
      };
    }
    return this.dialog.open(DateDialogComponent, config);
  }
}
