import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appActions } from 'src/app/actions/app.actions';
import { ActiveRelations } from 'src/app/contracts/active-relations';
import { User } from 'src/app/contracts/user.contract';
import { appFeature, AppState } from '../../reducers/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private appStore: Store) {}

  public dispatch(action: Action): void {
    this.appStore.dispatch(action);
  }

  setPickingDate(page: string): void {
    this.appStore.dispatch(appActions.changepreviouspage({ prevPage: page }));
  }

  getRelations(): Observable<any> {
    return this.appStore.select(appFeature.selectRelations);
  }

  getConnectivity(): Observable<boolean> {
    return this.appStore.select(appFeature.selectConnected);
  }

  getPreviousPage(): Observable<string> {
    return this.appStore.select(appFeature.selectPreviousPage);
  }

  getRelationByAd(relationAd: string): Observable<any> {
    return this.getRelations().pipe(map((relations: any[]) => relations.find((relation: any): boolean => relation.ad === relationAd)));
  }

  getUser(): Observable<User> {
    return this.appStore.select(appFeature.selectUser);
  }

  getApiUrl(): Observable<string> {
    return this.appStore.select(appFeature.selectApiUrl);
  }

  getActiveRelation(): Observable<ActiveRelations> {
    return this.appStore.select(appFeature.selectActiveRelation);
  }

  getAppState(): Observable<AppState> {
    return this.appStore.select(appFeature.selectAppStateState);
  }
}
