import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { UserDataHttpService } from '../../data/services/http/resources/userData.http.service';
import { AuthService } from '../services/auth/auth.service';
import { Snackbar } from '../../snackbar/classes/snackbar';
import { AppService } from 'src/app/services/app/app.service';
import { zip } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private userService: UserDataHttpService,
    private snackbar: Snackbar
  ) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  static addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // 3rd party api - cannot add any headers
    if (request.url == 'https://api.ipify.org?format=json') {
      return;
    }
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    return this.authService.getAccessToken().pipe(
      switchMap(accessToken => next.handle(TokenInterceptor.addTokenToRequest(request, accessToken))),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if ((<HttpErrorResponse>err).status === 401) {
            if (this.isRefreshingToken) {
              this.authService.logout();
              return throwError(() => err);
            }
            return this.handle401Error(request, next, err);
          }
          // Not a 401 unauth, so we display the api error to the user
          this.appService.getUser().subscribe(user => {
            if (err.error != null) {
              if (user.name === 'Test user') {
                // TEST USER
                return this.snackbar.open(err.error['techMessage'], err.error['severity'], false, -1);
              }
              // USER
              return this.snackbar.open(err.error['userFriendlyMessage'], err.error['severity']);
            }
            if (err.message != null) {
              // no error : show message
              if (user.name === 'Test user') {
                // TEST USER
                return this.snackbar.open(err.message, 'CLOSE', false, -1);
              }
              // USER
              return this.snackbar.message(err.message);
            }
            // no error and no message : show to user
            if (user.name === 'Test user') {
              // TEST USER
              this.snackbar.open('No error message available', 'close', false, -1);
            } else {
              // USER
              this.snackbar.message('No error message available');
            }
          });
          return throwError(() => err);
        }
      })
    );
  }

  private async handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    if (request.url.includes('PostFetchTokenV2')) {
      // if api is login > let result will give error : can't get username when user is not loggen in
      if (error.error.userFriendlyMessage === 'User login is blocked') {
        this.snackbar.message('Deze gebruiker is geblokkeert, neem contact met uw administrator op');
      } else {
        this.snackbar.message('Ongeldige login');
      }
      return throwError(() => '401');
    } else if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      if (!(await firstValueFrom(this.authService.getRefreshToken()))) {
        this.isRefreshingToken = false;
        this.authService.logout();
        return throwError(() => 'No refresh token found.');
      }

      return this.authService.getRefreshToken().pipe(
        switchMap(refreshToken => zip(this.userService.postRefresh(refreshToken), this.authService.getAccessToken())),
        switchMap(([loginResponse, accessToken]) => {
          if (loginResponse) {
            this.authService.login(loginResponse.access_token, loginResponse.refresh_token, loginResponse.expires);
            this.tokenSubject.next(accessToken);
            return next.handle(TokenInterceptor.addTokenToRequest(request, accessToken));
          }
          return <any>this.authService.logout();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(TokenInterceptor.addTokenToRequest(request, token));
        })
      );
    }
  }
}
