import { UiState } from '../../../../../reducers/ui.reducer';
import { Tile } from '../../contracts/tile.contract';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SelectionModel, SelectionChange } from '@angular/cdk/collections';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss']
})
export class GridListComponent implements OnInit, OnDestroy {
  @Input() cols = 4;
  @Input() rowHeight = '2:1';
  @Input() gutterSize = '15px';
  @Input() tiles: Tile[];
  @Input() multiselect: boolean;
  @Input() set selectAll(_selectAll: boolean) {
    if (!this.multiselect) {
      throw new Error('multiselect must be enabled to select all');
    }
    if (_selectAll) {
      this.selections.select(...this.tiles);
    } else {
      if (this.selections) {
        // if all are selected, deselect all
        if (this.selections.selected.length === this.tiles.length) {
          this.selections.clear();
        }
      }
    }
  }
  @Output() tileClick = new EventEmitter<string>();
  @Output() tileSelection = new EventEmitter<SelectionChange<Tile>>();

  uiStateSubscription: Subscription;
  selections: SelectionModel<Tile>;
  $tileSelection: Subscription;

  constructor(private router: Router, private store: Store<UiState>) {}

  ngOnInit(): void {
    if (this.multiselect) {
      this.selections = new SelectionModel<Tile>(true, [], true);
      this.$tileSelection = this.selections.changed.subscribe(change => this.tileSelection.emit(change));
    }
    this.uiStateSubscription = this.store
      .select((state: any) => state.uiState)
      .subscribe(uiState => {
        this.cols = this.cols !== 4 ? this.cols : uiState.gridColumnsPerRow;
      });
  }

  ngOnDestroy(): void {
    this.uiStateSubscription.unsubscribe();
    if (this.multiselect) {
      this.$tileSelection.unsubscribe();
    }
  }

  tileContents(tile: Tile): string | void {
    if (typeof tile.condition === 'undefined') {
      return tile.text;
    }
  }

  getTileIconVariant(icon: IconDefinition | string): string {
    return typeof icon;
  }

  onTileClick(tile: Tile): void {
    if (tile.emits) {
      this.tileClick.emit(tile.emits);
    }
    if (this.multiselect) {
      this.selections.toggle(tile);
    }
    if (tile.link) {
      this.router.navigate([tile.link]);
    }
  }

  $IconDefinition(icon: unknown): IconDefinition {
    return icon as IconDefinition;
  }

  $string(icon: unknown): string {
    return icon as string;
  }
}
