import * as Actions from '../actions/whs.actions';
import { TerminalDTO } from '../../shared/data/models/whsDTOs/terminal.dto';
import { WhsOrderDataDTO } from '../../shared/data/models/whsDTOs/whsOrderData.dto';

export interface WhsState {
  terminal: TerminalDTO;
  pickingOrder: WhsOrderDataDTO;
  pickingDate: Date;
}
export const initialState: WhsState = {
  terminal: null,
  pickingOrder: null,
  pickingDate: null
};

export function whsReducer(state = initialState, action: Actions.WhsActions): WhsState {
  switch (action.type) {
    case Actions.ActionTypes.Clear: {
      return initialState;
    }
    case Actions.ActionTypes.SetTerminal: {
      return {
        ...state,
        terminal: action.payload
      };
    }
    case Actions.ActionTypes.SetPickingOrder: {
      return {
        ...state,
        pickingOrder: action.payload
      };
    }
    case Actions.ActionTypes.SetPickingDate: {
      return {
        ...state,
        pickingDate: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
