import { DialogButton, DialogAlert } from './dialog-types';

export default interface IDialogData {
  /**
   * The title to display in the header of the dialog
   */
  title: NonNullable<string>;
  /**
   * The message to display in the body of the box,
   * can either be a single string or a list of strings
   */
  message: NonNullable<string> | NonNullable<Array<string>>;
  /**
   * Eerste is naam van actie,
   * tweede is kleur van knop (warn, primary, accent),
   * derde is optionele return waarde (default is naam van actie)
   * @example  [['annuleren', 'primary', false], ['ok', 'primary', true]]
   */
  actions?: DialogButton[];
  /**
   * The alertness of the dialog
   */
  alert?: DialogAlert;
}

export const DialogData: IDialogData = {
  title: null,
  message: null,
  actions: [
    ['annuleren', 'primary', false],
    ['ok', 'primary', true]
  ],
  alert: DialogAlert.NORMAL
};
