import { User } from '../contracts/user.contract';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActiveRelations } from '../contracts/active-relations';

export const appActions = createActionGroup({
  source: 'App',
  events: {
    clear: emptyProps(),
    connectivityChange: props<{ conn: boolean }>(),
    addUser: props<{ user: User }>(),
    addRelations: props<{ rels: unknown }>(),
    changeEmail: props<{ email: string }>(),
    changeApiUrl: props<{ apiurl: string }>(),
    changeCmpId: props<{ cmpId: string }>(),
    changeActiveRelation: props<{ activeRels: ActiveRelations }>(),
    changePreviousPage: props<{ prevPage: string }>()
  }
});
