import { WebcamModule } from 'ngx-webcam';
import { AuthModule } from './modules/shared/auth/auth.module';
import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './components/home/home.component';
import { NavbarModule } from './modules/shared/navbar/navbar.module';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { appFeature } from './reducers/app.reducer';
import { logReducer } from './reducers/log.reducer';
import { whsReducer } from '././modules/whs/reducers/whs.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GridModule } from './modules/shared/grid/grid.module';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SettingsComponent } from './components/settings/settings.component';
import { uiReducer } from './reducers/ui.reducer';
import { TokenInterceptor } from './modules/shared/auth/interceptors/token.interceptor';
import { MaterialModule } from './modules/shared/material/material.module';
import { APP_DATE_FORMATS, AppDateAdapter } from './adapters/date.adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { SnackbarModule } from './modules/shared/snackbar/snackbar.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DialogModule } from './modules/shared/dialog/dialog.module';
import { MatIconRegistry } from '@angular/material/icon';
import { vehicleReducer } from './modules/veldhovenTablet/reducers/vehicle.reducer';
import { UpdateService } from './services/service-worker/update.service';
import { ordqcReducer } from './modules/ordQc/reducers/ordqc.reducer';
import { scanFeature } from './modules/pasScan/reducers/scan.reducer';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

const reducers: ActionReducerMap<any> = {
  appState: appFeature.reducer,
  uiState: uiReducer,
  logState: logReducer,
  whsState: whsReducer,
  vehicleState: vehicleReducer,
  ordqcState: ordqcReducer,
  scanState: scanFeature.reducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['appState', 'authState', 'orderState', 'uiState', 'logState', 'whsState', 'vehicleState', 'ordqcState', 'scanState'],
    rehydrate: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent, HomeComponent, DashboardComponent, LoginComponent, SidebarComponent, SettingsComponent],
  imports: [
    // Angular Modules
    //BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // Third-party modules

    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictStateSerializability: false
      }
    }),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    WebcamModule,
    // Angular Material Modules
    MaterialModule,
    // Custom modules
    AppRoutingModule,
    NavbarModule,
    GridModule,
    AuthModule,
    SnackbarModule,
    DialogModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    },
    UpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
