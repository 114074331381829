import { Action } from '@ngrx/store';
import { StoredVehicleDTO } from '../../shared/data/models/vehicle.dto';

export enum ActionTypes {
  SetLicensePlate = '[VehicleState] SetLicensePlate',
  SetSelectedVehicles = '[VehicleState] SetSelectedVehicles'
}

export class SetLicensePlate implements Action {
  readonly type = ActionTypes.SetLicensePlate;

  constructor(public payload: string) {}
}

export class SetSelectedVehicles implements Action {
  readonly type = ActionTypes.SetSelectedVehicles;

  constructor(public payload: StoredVehicleDTO[]) {}
}

export type VehicleActions = SetLicensePlate | SetSelectedVehicles;
