import { createFeature, createReducer, on } from '@ngrx/store';
import { WhsOrderDataDTO } from '../../shared/data/models/whsDTOs/whsOrderData.dto';
import { scanActions } from '../actions/scan.actions';
// import * as Actions from '../actions/scan.actions';
export interface ScanState {
  scanOrder: WhsOrderDataDTO;
}

export const initialState: ScanState = {
  scanOrder: null
};

// export function scanReducer(state = initialState, action: Actions.ScanActions): ScanState {
//   switch (action.type) {
//     case Actions.ActionTypes.Clear: {
//       return initialState;
//     }
//     case Actions.ActionTypes.SetScanOrder: {
//       return {
//         ...state,
//         scanOrder: action.payload
//       };
//     }
//     default: {
//       return state;
//     }
//   }
// }

export const scanFeature = createFeature({
  name: 'scanState',
  reducer: createReducer(
    initialState,
    on(scanActions.clear, () => initialState),
    on(scanActions.setscanorder, (state, { whsOrderData }) => {
      return {
        ...state,
        scanOrder: whsOrderData
      };
    })
  )
});
