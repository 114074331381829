import { Action } from '@ngrx/store';

export enum ActionTypes {
  Login = '[AuthState] Login',
  Logout = '[AuthState] Logout',
  Clear = 'Clear'
}

export class LoginAction implements Action {
  readonly type = ActionTypes.Login;

  constructor(public payload: { accessToken: string; refreshToken: string; expiresAt: string; createdAt: number }) {}
}

export class ClearAction implements Action {
  readonly type = ActionTypes.Clear;
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.Logout;
}

export type AuthActions = ClearAction | LoginAction | LogoutAction;
