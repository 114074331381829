import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInputDialogData, InputDialogData, INumberInputOptions, ITextInputOptions } from '../../contracts/iinput-dialog-data';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  input: string | number = null;
  public inputDialogData = InputDialogData;

  public get numberInputOptions(): INumberInputOptions {
    return this.data.inputOptions as INumberInputOptions;
  }

  public get textInputOptions(): ITextInputOptions {
    return this.data.inputOptions as ITextInputOptions;
  }

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent, string | number>,
    @Inject(MAT_DIALOG_DATA) public data: IInputDialogData
  ) {}

  ngOnInit(): void {
    return;
  }

  public submitEntry(): string | number {
    return this.data.type === 'text' ? (this.input as string) : (this.input as number);
  }
}
